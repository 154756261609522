import React from "react";
import { createContext, useContext } from "react";
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material";

export const ThemeContext = createContext({});
export const useTheme = () => {
  return useContext(ThemeContext);
};

const MAIN_COLOR = "#64fff7";

export const ThemeProvider = ({ children }) => {
  const theme = createTheme({
    typography: {
      fontFamily: "Chakra Petch, sans-serif",
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    palette: {
      type: "dark",
      mode: "dark",
      primary: {
        main: MAIN_COLOR,
      },
      secondary: {
        main: "#f50057",
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&:before": {
              borderBottom: "none",
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            color: MAIN_COLOR,
          },
        },
      },
    },
  });

  return (
    <ThemeContext.Provider value={theme}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
