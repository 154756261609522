import styled from "styled-components";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "./TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";

export default function EnhancedTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const HEADCELLS = [
    {
      id: "rank",
      numeric: true,
      disablePadding: true,
      sort: true,
      label: "RANK",
    },
    {
      id: "address",
      numeric: false,
      disablePadding: true,
      sort: false,
      label: "ADDRESS",
    },
    {
      id: "tvl",
      numeric: true,
      disablePadding: true,
      sort: true,
      label: "TVL",
    },
  ];

  return (
    <StyledTableHead>
      <TableRow>
        {HEADCELLS.map((headCell) => {
          return (
            <StyledTableHeadCell
              key={headCell.id}
              align={
                headCell.id === "rank"
                  ? "left"
                  : headCell.numeric
                  ? "right"
                  : "left"
              }
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.sort ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </StyledTableHeadCell>
          );
        })}
      </TableRow>
    </StyledTableHead>
  );
}

const StyledTableHead = styled(TableHead)`
  position: relative;
`;

const StyledTableHeadCell = styled(StyledTableCell)`
  font-size: 2.25vmin !important;
  line-height: 4.25vmin !important;

  svg {
    font-size: 2.25vmin !important;
  }

  @media (max-width: 1000px) and (max-height: 1000px) {
    font-size: 4vmin !important;

    svg {
      font-size: 4vmin !important;
    }
  }

  &:first-child {
    padding-left: 1vmin;
  }

  &:last-child {
    padding-right: 1vmin;
  }
`;
