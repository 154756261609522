import TableCell from "@mui/material/TableCell";
import styled from "styled-components";

export default styled(TableCell)`
  font-size: 1.75vmin !important;
  line-height: 0 !important;

  @media (max-width: 1000px) and (max-height: 1000px) {
    font-size: 3.5vmin !important;
  }

  &.MuiTableCell-root {
    border-bottom: none;
    span.Mui-active {
      color: #64fff7;
    }
    &:hover {
      span.Mui-active {
        color: #64fff7;
        text-shadow: none;
      }
    }
  }
`;
