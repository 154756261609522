import styled from "styled-components";
import MuiTable from "@mui/material/Table";
import TablePagination from "@mui/material/TablePagination";
import { Button } from "@mui/material";
import EnhancedTableHead from "./TableHead";
import EnhancedTableBody from "./TableBody";
import { useState, useCallback, useMemo } from "react";

export const Table = ({ data, searchString, account }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("rank");
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const rows = (data ?? [])
    .map((row, i) => ({
      ...row,
      rank: i + 1,
    }))
    .filter(({ user, tvl }) =>
      user.toLowerCase().includes(searchString?.toLowerCase())
    );

  const myIndex = useMemo(
    () =>
      (rows ?? []).findIndex(
        ({ user }) => account?.toLowerCase() === user?.toLowerCase()
      ),
    [rows, account]
  );

  return (
    <TableWrapper>
      <MuiTable aria-labelledby="tableTitle" size={"medium"}>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={rows?.length ?? 0}
        />
        <EnhancedTableBody
          rows={rows}
          order={order}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
        ></EnhancedTableBody>
      </MuiTable>
      <TableOptions>
        {myIndex > -1 && (
          <JumpToMeButton
            onClick={() => setPage(Math.floor(myIndex / rowsPerPage))}
          >
            Jump To Me
          </JumpToMeButton>
        )}

        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={rows?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </TableOptions>
    </TableWrapper>
  );
};

const JumpToMeButton = styled(Button)`
  text-shadow: 0 0 0.2vmin #fff, 0 0 2vmin #64fff7, 0 0 3vmin #64fff7,
    0 0 5vmin #64fff7;
  font-size: 2vmin !important;

  @media (max-width: 1000px) and (max-height: 1000px) {
    font-size: 3.5vmin !important;
  }
`;

const TableOptions = styled.div`
  display: flex;
  align-items: center;
  bottom: -1vmin;
  position: relative;

  @media (max-width: 1000px) and (max-height: 1000px) and (orientation: portrait) {
    bottom: -4vmin;
  }
`;

const TableWrapper = styled.div`
  font-family: Chakra Petch, sans-serif;
  color: #64fff7;
  background: transparent;
  position: relative;
  top: 6vmin;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > table {
    width: auto;
    height: 79%;
    margin: 0 1.7% 0 0.4%;

    > tbody {
      position: relative;
      top: 0.75vmin;
    }
  }

  @media (max-width: 1000px) and (max-height: 1000px) {
    top: 7vmin;

    > table > tbody {
      top: 1.5vmin;
    }
  }

  @media (max-width: 1000px) and (max-height: 1000px) and (orientation: portrait) {
    top: 15vmin;

    > table {
      height: 75%;

      > tbody {
        top: 3vmin;
      }
    }
  }

  .MuiTablePagination-root {
    flex: 1;

    div {
      padding: 0;
      min-height: 0;
    }

    p {
      font-size: 2vmin;
    }

    svg {
      font-size: 4vmin;
    }

    @media (max-width: 1000px) and (max-height: 1000px) {
      p {
        font-size: 3vmin;
      }

      svg {
        font-size: 5vmin;
      }
    }
  }
`;
