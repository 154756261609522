import { ThemeProvider } from "./providers/theme";
import styled from "styled-components";
import { useWindowSize } from "./hooks/windowSize";
import { Input as MuiInput } from "@mui/material";
import { Table } from "./components/Table";
import { useState } from "react";
import data from "./assets/leaderboard-data";
import { init, useConnectWallet } from "@web3-onboard/react";
import logo from "./assets/gold_logo.png";
import { shortenAddress } from "./misc/utils";

import injectedModule from "@web3-onboard/injected-wallets";
import trezorModule from "@web3-onboard/trezor";
import ledgerModule from "@web3-onboard/ledger";
import walletConnectModule from "@web3-onboard/walletconnect";
import walletLinkModule from "@web3-onboard/walletlink";
import portisModule from "@web3-onboard/portis";
import fortmaticModule from "@web3-onboard/fortmatic";
import torusModule from "@web3-onboard/torus";
import keepkeyModule from "@web3-onboard/keepkey";

const injected = injectedModule();
const walletLink = walletLinkModule();
const walletConnect = walletConnectModule();

const portis = portisModule({
  apiKey: "4538f51b-584b-49a9-a9e0-68dc1e7d2e36",
});

const fortmatic = fortmaticModule({
  apiKey: "pk_test_6FBD65B3DA65FCDB",
});

const torus = torusModule();
const ledger = ledgerModule();
const keepkey = keepkeyModule();

const trezorOptions = {
  email: "",
  appUrl: "https://leaderboards.vampire.enso.finance",
};

const trezor = trezorModule(trezorOptions);

init({
  wallets: [
    ledger,
    trezor,
    walletConnect,
    keepkey,
    walletLink,
    injected,
    fortmatic,
    portis,
    torus,
  ],
  chains: [
    {
      id: "0x1",
      token: "ETH",
      label: "Mainnet",
      rpcUrl: "https://",
    },
  ],
  appMetadata: {
    name: "Enso",
    icon: logo,
    description: "Vampire Attack Leaderboards",
  },
});

export const App = () => {
  const [{ wallet, connecting }, connect] = useConnectWallet();
  const account =
    wallet && wallet.accounts[0] ? wallet.accounts[0].address : undefined;
  const size = useWindowSize();
  const [searchString, setSearchString] = useState("");

  document.body.style.width = `${size.width}px`;
  document.body.style.height = `${size.height}px`;

  return (
    <ThemeProvider>
      <Screen>
        <Header onClick={() => connect()}>
          {account
            ? shortenAddress(account, false)
            : connecting
            ? "Connecting..."
            : "Connect wallet"}
        </Header>
        <Input onChange={(e) => setSearchString(e?.currentTarget?.value)} />
        <Table
          data={data.sort(({ tvl: a }, { tvl: b }) => b - a)}
          searchString={searchString}
          account={account}
        />
      </Screen>
    </ThemeProvider>
  );
};

const Header = styled.h2`
  margin: 0;
  padding-top: 1.5%;
  width: 100%;
  text-align: center;
  transition: box-shadow 0.2s ease-in;
  font-size: 4vmin;

  @media (max-width: 1000px) and (max-height: 1000px) and (orientation: landscape) {
    font-size: 5.5vmin;
  }

  @media (max-width: 1000px) and (max-height: 1000px) and (orientation: portrait) {
    font-size: 8vmin;
  }

  &:hover {
    box-shadow: 0 -1vmin 1vmin rgba(255, 255, 255, 0.25),
      0 -2vmin 2vmin rgba(123, 237, 249, 0.25),
      0 -3vmin 3vmin rgba(123, 237, 249, 0.25),
      0 -4vmin 4vmin rgba(123, 237, 249, 0.25),
      0 -5vmin 5vmin rgba(123, 237, 249, 0.25),
      0 -6vmin 6vmin rgba(123, 237, 249, 0.25);
    cursor: pointer;
  }
`;

const Input = styled(MuiInput)`
  margin-left: 2%;
  top: 4.25vmin;
  width: 48%;
  font-size: 1.5vmin !important;

  @media (max-width: 1000px) and (max-height: 1000px) {
    font-size: 2vmin !important;
  }

  @media (max-width: 1000px) and (max-height: 1000px) and (orientation: portrait) {
    font-size: 3vmin !important;
    top: 9vmin;
  }

  &::before {
    border-bottom: none !important;
  }
`;

const Screen = styled.div`
  transform: translateY(-7.5vmin);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vmin;
  aspect-ratio: 1.5 / 1;

  @media (max-width: 1000px) and (max-height: 1000px) and (orientation: landscape) {
    width: 100vmin;
  }

  @media (max-width: 1000px) and (max-height: 1000px) and (orientation: portrait) {
    transform: translateY(-15vmin);
    aspect-ratio: unset;
    height: 125vmin;
  }

  &::before {
    content: "";
    position: absolute;
    background: url("assets/dialog.png") no-repeat;
    background-size: 100% 100%;
    background-position: center;
    z-index: -1;
    height: 130%;
    top: -2%;
    margin-left: -0.25%;
    width: 145.5%;
  }
`;

export default App;
