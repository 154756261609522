import { useState, useEffect } from "react";

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      const width = Math.min(
        window.visualViewport.width,
        window.screen.width,
        window.innerWidth
      );
      const height = Math.min(
        window.visualViewport.height,
        window.screen.height,
        window.innerHeight
      );

      setWindowSize({
        width,
        height,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};
