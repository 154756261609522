export const shortenAddress = (longAddress, veryShort) => {
  if (longAddress.length > 20) {
    if (veryShort) {
      return `${longAddress.slice(0, 4)}...${longAddress.slice(
        longAddress.length - 2
      )}`;
    }
    return `${longAddress.slice(0, 8)}...${longAddress.slice(
      longAddress.length - 5
    )}`;
  }
  return longAddress;
};

export const abbreviateNumber = (num) => {
  const n = Math.round(num);
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};
