import TableBody from "@mui/material/TableBody";
import StyledTableCell from "./TableCell";
import { useCallback } from "react";
import { useWindowSize } from "../hooks/windowSize";
import styled, { css } from "styled-components";
import { abbreviateNumber, shortenAddress } from "../misc/utils";
import TableRow from "@mui/material/TableRow";
import OtherRank from "../assets/other_logo.png";
import GoldRank from "../assets/gold_logo.png";
import SilverRank from "../assets/silver_logo.png";
import BronzeRank from "../assets/bronze_logo.png";

export default function EnhancedTableBody({
  rows,
  order,
  orderBy,
  page,
  rowsPerPage,
}) {
  const size = useWindowSize();

  const getEnsoIcon = (rank) => {
    switch (rank) {
      case 1:
        return <img src={GoldRank} alt="gold" />;
      case 2:
        return <img src={SilverRank} alt="silver" />;
      case 3:
        return <img src={BronzeRank} alt="bronze" />;
      default:
        return <img src={OtherRank} alt="other" />;
    }
  };

  const descendingComparator = useCallback((a, b, orderBy) => {
    if (Number(b[orderBy]) < Number(a[orderBy])) {
      return -1;
    }
    if (Number(b[orderBy]) > Number(a[orderBy])) {
      return 1;
    }
    return 0;
  }, []);

  const getComparator = useCallback(
    (order, orderBy) => {
      return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    },
    [descendingComparator]
  );

  return (
    <TableBody>
      {rows
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;
          const rowProps = {
            hover: true,
            role: "cell",
            tabIndex: -1,
            key: labelId,
            color: index === 0 ? "#ffe719" : "initial",
          };
          return (
            <StyledTableRow
              {...rowProps}
              rank={row.rank}
              onClick={() =>
                window?.open(
                  `https://etherscan.io/address/${row.user}`,
                  "_blank"
                )
              }
            >
              <FirstColumnCell align="left" padding={"none"}>
                <div>
                  <span>{getEnsoIcon(row.rank)}</span>
                  <span>#{row.rank}</span>
                </div>
              </FirstColumnCell>
              <StyledTableCell align="left" padding={"none"}>
                {size.width <= 1000 && size.height <= 1000
                  ? shortenAddress(row.user, false)
                  : row.user}
              </StyledTableCell>
              <StyledTableCell
                align="right"
                padding={"none"}
                style={{ paddingRight: "15px" }}
              >
                ${" "}
                {size.width <= 800
                  ? abbreviateNumber(row.tvl)
                  : Number(row.tvl).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </StyledTableCell>
            </StyledTableRow>
          );
        })}
    </TableBody>
  );
}

const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    cursor: pointer;    
    user-select: none;
    ${({ rank }) => {
      switch (rank) {
        case 1:
          return css`
            background-image: url("assets/gold-cropped.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            > td {
              color: #ffe719;
              text-shadow: 1px 1px 10px #ffe719, 1px 1px 10px #2b220b;
            }
          `;
        case 2:
          return css`
            background-image: url("assets/silver-cropped.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            > td {
              color: #fefefe;
              text-shadow: 1px 1px 10px #fefefe, 1px 1px 10px #2b220b;
            }
          `;
        case 3:
          return css`
            background-image: url("assets/bronze-cropped.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            > td {
              color: #d8a486;
              text-shadow: 1px 1px 10px #d8a486, 1px 1px 10px #2b220b;
            }
          `;
        default:
          return css``;
      }
    }}
`;

const FirstColumnCell = styled(StyledTableCell)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: row;
  padding: 0.23vmin 0 !important;

  > div {
    display: flex;
    align-items: center;
    position: relative;

    span {
      display: flex;
      align-items: center;
      margin-left: 1vmin;

      img {
        height: 3vmin;
        width: 3vmin;

        @media (max-width: 1000px) and (max-height: 1000px) {
          height: 4vmin;
          width: 4vmin;
        }

        @media (max-width: 1000px) and (max-height: 1000px) and (orientation: portrait) {
          height: 5vmin;
          width: 5vmin;
        }
      }
    }
  }
`;
